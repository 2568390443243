<template>
  <LiefengContentManage>
    <template v-slot:title>出入记录
      <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarLeft>
      <div style="margin-left: 20px" class="header-wrapper">

      </div>
    </template>
    <template v-slot:toolsbarRight>
      <template>
        <Form class="search" :label-width="100" :model="formItem" :label-colon="true" :inline="true">
          <FormItem label="姓名/手机号">
            <Input v-model="formItem.mobileOrUserName" placeholder="填写姓名/手机号"></Input>
          </FormItem>
          <!-- <FormItem label="手机号">
            <Input v-model="formItem.mobile" placeholder="填写出入手机号"></Input>
          </FormItem> -->
          <FormItem label="出入地址">
            <Input v-model="formItem.positionName" placeholder="填写出入地址"></Input>
          </FormItem>
          <FormItem label="范围" :label-width="80">
            <Cascader
                transfer
                v-model="orgCodeArray"
                :data="publishList"
                :load-data="loadCommunity"
                change-on-select
                placeholder="选择范围"
            ></Cascader>
          </FormItem>
          <FormItem label="日期" :label-width="80" >
            <Row>
              <Col span="12">
                <DatePicker transfer @on-change="handTimeFn" type="date" placeholder="选择日期"
                            style="width: 200px"></DatePicker>
              </Col>
            </Row>
          </FormItem>
            <Button type="primary" icon="ios-search-outline" style="margin-left: 10px; " @click="search">搜索</Button>
            <Button type="success" icon="ios-refresh" style="margin-left: 10px;" @click="reset">重置</Button>
            <Button type="info" icon="ios-download-outline" style="margin-left: 10px;" @click="exportAllMessage">导出</Button>
          
          <Button type="error" style="margin-left: 10px;" @click="returnFn">
            <Icon type="ios-arrow-back"></Icon>
            返回
          </Button>
        </Form>
      </template>
    </template>
    <template v-slot:contentArea>
      <div class="container">
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :pageSize="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
      </div>
    </template>
  </LiefengContentManage>
</template>

<script>
//@route('/recordsAcc')
import LiefengTable from "@/components/LiefengTable";
import LiefengContentManage from "@/components/LiefengContent3";


export default {
  components: {
    LiefengTable,
    LiefengContentManage,
  },
  data() {
    return {
      //脱敏
      hasSensitive:true,
      // 发布范围列表
      publishList: [],


      //搜索
      orgCodeArray: [],
      formItem: {},

      tableSelectArr: [], //table选中的
      //table列表
      tableColumns: [
        // {
        //   type: "selection",
        //   width: 60,
        //   align: "center"
        // },
        {
          title: '序号',
          align: "center",
          width: 80,
          render: (h, params) => {
            return h("span", {}, params.index + 1)
          }
        },
        {
          title: '区',
          key: 'zone',
          minWidth: 100,
          align: "center",
        },
        {
          title: '街道',
          key: 'street',
          align: "center",
          minWidth: 130
        },
        {
          title: '社区',
          key: 'orgName',
          align: "center",
          minWidth: 80,
        },
        {
          title: '姓名',
          key: 'userName',
          minWidth: 130,
          align: "center",
        },
        {
          title: '手机号',
          key: 'mobile',
          minWidth: 130,
          align: "center",
        },
        {
          title: '住址',
          key: 'address',
          minWidth: 100,
          align: "center",
        },
        {
          title: '出入地点',
          key: 'positionName',
          minWidth: 100,
          align: "center",
        },
        {
          title: '出入时间',
          key: 'gmtCreate',
          minWidth: 100,
          align: "center",
        },
        {
          title: '出入状态',
          key: 'positionType',
          minWidth: 150,
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {},
                  params.row.positionType === '1' ? '入' : params.row.positionType === '2' ? '出' : ''
              )
            ])
          }
        },
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量
    };
  },
  mounted() {
    // 获取广州市的区
    this.getPulishData(4401, "publishList");


    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });
  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },
  methods: {
    // 获取区
    getPulishData(code, list) {

      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if (res.code == 200) {
          this[list] = res.dataList.map(item => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: []
            };
          });
        }
      });
    },

    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      // console.log(arrCode)
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if (res.code == 200) {
          if (!item.index) {
            item.children = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1
              };
            });
          } else {
            item.children = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                index: 1
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },


    tableSelect(data) { //表格选中
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
      this.tableSelectDataId = data
    },

    //日期
    handTimeFn(val) {
      this.formItem.operData = val
    },

    //选择器
    changeSelect(val) {
      // console.log(val,'val')
    },

    // 点击导出全部信息按钮
    exportAllMessage() {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "确认导出全部信息",
        onOk: () => {
          this.$get('/datamsg/api/pc/community/position/exportRecordList', {
            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            selfOrgCode: parent.vue.loginInfo.userinfo.orgCode,
          }).then(res => {
            if (res.code == 200) {
              if (res.data) {
                if (res.data.slice(0, 4) == 'http' && res.data.slice(0, 5) !== 'https') {
                  res.data = res.data.replace('http', 'https')
                }
              }
              let link = document.createElement("a");
              link.href = res.data
              document.body.appendChild(link);
              link.click();
              link.download = '导出全部信息'
              document.body.removeChild(link);
              this.$Message.destroy();
            } else {
              this.$Message.warning({
                content: res.desc,
                background: true
              });
            }
          })

        }
      });
    },


    download(data) {
      const url = window.URL.createObjectURL(
          new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
      );
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `社区位置通行模块记录`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.$Message.destroy();
    },


    //搜索
    search() {
      let arr = this.orgCodeArray[this.orgCodeArray.length - 1]
      if (arr) {
        let arr1 = arr.split('-')
        this.formItem.orgCode = arr1[arr1.length - 1]
      } else {
        this.formItem.orgCode = ''
      }
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });

    },
    //重置
    reset() {
      this.formItem = {};
      this.orgCodeArray = []
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },

    returnFn() {
      this.$router.push({
        path: '/communityaccess',
        query: {menuId: this.$route.query.menuId}
      })
    },

    hadlePageSize(obj) {
      this.loading = true;
      this.formItem.orgCode = this.formItem.orgCode ? this.formItem.orgCode : '4401'
      this.$get("/datamsg/api/pc/community/position/queryByRecordPage", {
        ...this.formItem,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        selfOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
        hasSensitive:this.hasSensitive
      }).then(res => {
        this.loading = false;
        if (res.code == 200 && res.dataList) {

          res.dataList.map(item => {
            item.gmtCreate = item.gmtCreate
                ? this.$core.formatDate(
                    new Date(item.gmtCreate),
                    "yyyy-MM-dd hh:mm:ss"
                )
                : "";
          });
          this.tableData = res.dataList;
          this.page = res.currentPage;
          this.pageSize = res.pageSize;
          this.total = res.maxCount;

        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      })
          .catch(err => {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: "获取数据失败"
            });
          });
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
  },
};
</script>

<style scoped lang='less'>
// .container {
//   height: calc(100vh - 85px);
//   width: calc(100vw - 20px);
// }


// /deep/ #toolsbarLeft {
//   //width: 300%;
//   flex: 1;
// }

// /deep/ #toolsbarRight {
//   flex: 6;
//   //width: 1000%;
// }

// .header-wrapper {
//   display: flex;
// }

// .headerClass {
//   display: flex;
//   width: 100%;
//   text-align: right;

//   /deep/ .ivu-form-item {
//     margin-bottom: 0;

//     .ivu-form-item-content {
//       input {
//         width: 180px;
//       }
//     }

//     .ivu-select-selection {
//       width: 180px;
//     }
//   }
// }


// /deep/ #toolsbarRight {
//   width: 1000%;

//   .search {
//     width: 100%;
//     text-align: right;

//     .ivu-form-item {
//       margin-bottom: 0;
//     }
//   }
// }
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}

</style>


